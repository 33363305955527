import React from 'react'
import runveer from '../imgs/02.png'

function SectionThree() {
  return (
    <div className='Sectionthree'>
      <div className='Sectionthree-left'>
        <p>
          “RunVeer 5.0” is the fifth edition of The Infantry Marathon and will collate the celebration of 77th Infantry Day. Gather up to display your dedication for the nation and the spirit of solidarity.
        </p>

      </div>
      <div className='Sectionthree-right'>
        {/* <img className='run' src={runveer} /> */}
        <iframe src='https://www.youtube.com/embed/WaF8jTcD3iM'
        frameborder='0'
        allow='autoplay; encrypted-media'
        allowfullscreen
        width={400}
        height={250}
        title='video'
/>
      </div>

    </div>
  )
}

export default SectionThree