import React from 'react'
import CountUp from "react-countup";
import { useNavigate } from 'react-router-dom';

const Terms2 = () => {
    const navigate = useNavigate();

    return (
        <>
            <nav className="navbar">
                <div className="nav1">
                    <div className="nav11">
                        <p className="counter">
                            <CountUp start={0} end={100} duration={3} delay={0} /> +
                            Runveer's Joined
                        </p>
                    </div>
                    <div className="nav11">
                        <p onClick={() => {
                            navigate('/');
                        }}>Home</p>
                    </div>
                </div>
            </nav>

            <div className="terms">
                <div className="terms1">
                    <div style={{ fontSize: "23px", fontWeight: "600", marginBottom: "20px" }} className="terms11 text-center">
                        Terms and Condition - Virtual Marathon (International)
                    </div>
                    <div className="terms12">
                        <h2 className='blue'>About the Race</h2>
                        <p>Be a Runveer and join us in celebrating brotherhood beyond the battlefield.
                            RUNVEER 5.0 is the 5th edition of The Infantry Marathon which is organized every year by The Infantry School, Mhow. This Marathon is organized to commemorate Infantry Day which is the foundation day of Infantry, the largest fighting arm of the Indian Army.
                            Come together, and join us in the mission of fitness where we pay tribute to our Bravehearts by running with the soldiers of the Indian Army. Run for our nation!
                        </p>

                        <h2 className='blue'>Race format</h2>
                        <ul>
                            <li>Participants are free to choose where they want to run.</li>
                            <li>The 04 categories for runners to choose from are 5km, 10km, 21km, and 42km.</li>
                            <li>On any one of these days - October 27, October 28, or October 29, 2023, from 00:00 to 23:59(IST), participants must run the entire distance for their selected category in one go.</li>
                        </ul>

                        <h2 className='blue'>Registration</h2>
                        <b>Please read all the sections in detail, carefully before registration</b>
                        <ul>
                            <li>Registration fee for virtual run (International) is Rs. 1999 (including delivery charges). </li>
                            <li>On successful registration and payment using this <a href="tim.dotdx.in">link</a>, participants will receive a confirmation mail with their registration ID.</li>
                        </ul>

                        <h2 className='blue'>What's in it for me?</h2>
                        <ul>
                            <li>Test your fitness and push your endurance.</li>
                            <li>All registered participants will receive a bib and t-shirt (We cannot deliver medals due to custom restrictions. You can fill in your Indian address if you want the medal to be delivered.)</li>
                        </ul>

                        <h2 className='blue'>General Instructions</h2>
                        <ul>
                            <li>Participants will be required to follow all the prevailing rules and directives set by the government and organisers from time to time.</li>
                            <li>Participants to make sure that they do not break the protocol of maintaining Social Distance.</li>
                            <li>The Run is to be done at the participant’s convenience, anytime between  00:00 to 23:59 IST either on October 27 or October 28 or October 29, 2023 in one go.</li>
                            <li>Participants should record their runs by using fitness apps (App Used for Run - Strava, NoiseFit: Health & Fitness, Nike Run Club, Fitbit, Map My Run by Under Armour, Runkeeper, Apple Fitness Plus, Garmin Connect and Others).</li>
                            <li>Participants will have to upload the data by midnight of October 29th, 2023 on “Submit the run page of the website.</li>
                            <li>Participants should run only in their selected category and not exceed the distance.</li>
                            <li>Participants to warm-up well before the run and to cool down and stretch post-run and keep themselves hydrated.</li>
                            <li>All participants of ages 15 and under MUST be accompanied by an adult (Parent/Guardian). It is the responsibility of the parent or guardian of the entrant to ensure that participants under the age of 15 are capable of completing the event. </li>
                            <li>Do not participate if you have any medical conditions. Event organizers/promoters are not responsible for your health and safety.</li>
                            <li>Participants are responsible for their own health and safety and are participating in the event at their own risk. The Event promoters/their employees/Event Sponsors/Event Partners/their associates will not be responsible or liable for any sort of bodily injury, illness or death sustained to or by you whatsoever and howsoever this is caused.</li>
                            <li>If the event has to be cancelled then the Participant’s entry fee shall be reimbursed in full within 28 days of the event cancellation. The Organisers shall not be liable for any other costs that may have been incurred by the Participant in relation to their entry.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Terms2;
