import React from 'react'
import insta from '../imgs/social-removebg-preview.png'
import phone from '../imgs/phone-removebg-preview.png'
import wa from '../imgs/whatsapp-removebg-preview.png'
import fblogo from '../imgs/fblogo.png'
import instalogo from '../imgs/instalogo.jpg'
import { Link, useNavigate } from 'react-router-dom'

function Footer() {
  const navigate=useNavigate();

  return (
    <div className='footer'>
      {/* <div className='social'>
        <img src={insta} />
      </div> */}

      <div className='contact'>
        <div className='contact-no'>
          <img src={phone} />
          <p>Contact for Mhow Marathon: 7251994553</p>
        </div>
        <div className='contact-no'>
          <p>Contact for Virtual Marathon: 7898893694</p>
        </div>
        <div className='contact-no'>
          <Link style={{color: "white", textDecoration: 'none'}} to="/faq">FAQ</Link>
         <Link to='https://www.facebook.com/theinfantrymarathon?mibextid=ZbWKwL'>
          <img src={fblogo} />
         </Link>
         <Link to='https://www.instagram.com/theinfantrymarathon/'>
          <img src={instalogo} />
        </Link>
        </div>
      </div>
    </div>
  )
}

export default Footer