import React from 'react'
import product1 from '../imgs/05.png'
import product2 from '../imgs/04.png'
import product3 from '../imgs/03.png'
import product4 from '../imgs/05.png'



function Sectionfourth() {
  return (
    <>
      <div className='sectionfour'>
        {/* <div className='card'>
          <img src={product1} />
        </div> */}
        <div className='card'>
          <img src={product2} />
        </div>
        <div className='card'>
          <img src={product3} />
        </div>
        <div className='card'>
          <img src={product1} />
        </div>

        <div>
          <h1 className='give'><i>Giveaways</i></h1>
          <div className='borderbottm'></div>
        </div>
      </div>

      {/* <div className="sec5">
        <div className="sec51">
          <div className="sec511">
            <div className="sec5111"></div>
            <h3>Our Partners</h3>
            <div className="sec5111"></div>
          </div>

          <div className="sec512">
            <div className="sec5-card">
              
            </div>
            <div className="sec5-card">

            </div>
            <div className="sec5-card">

            </div>
            <div className="sec5-card">

            </div>
            <div className="sec5-card">

            </div>
            <div className="sec5-card">

            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default Sectionfourth