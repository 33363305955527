import React, { useState } from 'react'
import { Button, FormGroup, Input, Label } from 'reactstrap';

// const baseUrl = "http://localhost:5000";
// const baseUrl = "https://api.dotdx.in";
const baseUrl = "https://monkfish-app-zxijf.ondigitalocean.app";

const SubmitRun = () => {
    const [value, setValue] = useState({
        runveerId: '',
        phone: '',
        email: '',
        app: '',
        app1: '',
        screenshot: '',
        link: ''
    });

    const handleChange = (e) => {
        if(e.target.name==="screenshot")
        {
            setValue({ ...value, [e.target.name]: e.target.files[0] });
        }
        else
        {
            setValue({ ...value, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(value.screenshot==='' && value.link==='')
        {
            alert('Screenshot/Link is required');
        }
        else
        {
            // console.log(value);
            let formdata=new FormData();
            formdata.append('runveerID', value.runveerId);
            formdata.append('mobileNumber', value.phone);
            formdata.append('emailId', value.email);
            formdata.append('appUsed', value.app==="Others" ? value.app1 : value.app);
            formdata.append('screenshot', value.link==="" ? "" : value.link);
            formdata.append('image', value.link==="" ? value.screenshot : "");

            let resp=await fetch(`${baseUrl}/api/certificate/registration`,{
                method: 'POST',
                body: formdata
            });
            let data=await resp.json();
            console.log(data);
            alert(data.message);
            if(data.statusCode === 200)
            {
                setValue({
                    runveerId: '',
                    phone: '',
                    email: '',
                    app: '',
                    app1: '',
                    screenshot: '',
                    link: ''
                });
            }
        }
        
    };

    return (
        <>
            <div className="sub">
                <div className="sub1">
                    <div className="sub11">
                        <h2>Virtual Run Submission </h2>
                    </div>
                    <div className="sub12">
                        <form onSubmit={handleSubmit}>
                            <Input name="runveerId" onChange={handleChange} value={value.runveerId} placeholder='Runveer ID' required />
                            <Input name="phone" onChange={handleChange} value={value.phone} placeholder='Registered Mobile Number' required />
                            <Input name="email" onChange={handleChange} value={value.email} placeholder='Email ID' required />

                            <FormGroup>
                                <Label for="app">
                                    App Used for Run
                                </Label>
                                <Input
                                    id="app"
                                    name="app"
                                    type="select"
                                    onChange={handleChange}
                                    value={value.app}
                                    required
                                >
                                   <option value="Strava">Strava</option> 
                                   <option value="NoiseFit: Health & Fitness">NoiseFit: Health & Fitness</option> 
                                   <option value="Nike Run Club">Nike Run Club</option> 
                                   <option value="Fitbit">Fitbit</option> 
                                   <option value="Map My Run by Under Armour ">Map My Run by Under Armour </option> 
                                   <option value="Runkeeper">Runkeeper</option> 
                                   <option value="Apple Fitness Plus">Apple Fitness Plus</option> 
                                   <option value="Garmin Connect">Garmin Connect</option> 
                                   <option value="Others">Others</option> 
                                </Input>
                            </FormGroup>

                            {value.app==="Others" && <Input name="app1" onChange={handleChange} value={value.app1} placeholder='Please Specify' required />}

                            <p>Upload a Screenshot/Link of your run</p>
                            <Input type="file" name="screenshot" onChange={handleChange} />
                            <Input name="link" onChange={handleChange} value={value.link} placeholder='Link' />

                            <Button type='submit'>Submit</Button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubmitRun;
