import React from 'react'
import army from "../imgs/01.png"

function SectionTwo() {
  return (
    <>
      <div className='section-two'>
        <div className='section-two-left'>
          <h1>About The Infantry Marathon</h1>
          <h3>“Impossible takes Time. Difficult will be done immediately!”</h3>
          <p>The Infantry Marathon is a celebration of the grandeur and camaraderie of the Indian Army along with the love for fellow citizens and nation. Every year the Infantry Marathon is organised to commemorate the Infantry Day which is the foundation day of Infantry, the largest fighting arm of the Indian Army.</p>
          <br />  <p>While the saga of battlefields and wars has lionised the Army, the motive of The Infantry Marathon is to share the love for country in equal spirit with army brothers and natives hand in hand.</p>
        </div>
        <div className="section-two-right">
          <img src="/images/wewe.png" alt="wewe" />
        </div>
      </div>
      <div className='secn-main'>
        <div className="flex">
          <div className='secn1'>
            <h3>How to Run in Virtual Marathon :</h3>
            <ul>
              <li>Fill the Virtual Registration Form carefully to register yourselves.</li>
              <li>Take extra care in filling correct address and contact details in the form.</li>
              <li>Your Marathon Gratification Kit (T-shirt, Medal & BIB) will be delivered at the provided address before the marathon. </li>
              <li>Choose a safe route to run on Marathon Day (i.e., 27,28,29th Oct 2023). You can run anywhere and anytime on that day.</li>
              <li>Track your run via certified tracking apps like MapMyRun, Strava and Runkeeper. </li>
              <li>After completing your run, submit it at our "Submit Your Run" page over the website.</li>
              <li>Once your run is verified by the TIM Team, a certificate shall be generated on the "Certificate" page of the website. You can download it. </li>
              <li>Voila! You did it, Runveer.</li>
            </ul>
          </div>
          <div className='secn2'>
            <h3>How to Run in Mhow Marathon :</h3>
            <ul>
              <li>This will be the traditional marathon going to be held at Garrison Ground, Mhow.</li>
              <li>Fill the Registration Form carefully to register yourselves.</li>
              <li>BIB Expo Dates shall be announced before the Marathon for you to collect your  Gratification Kit (T-shirt & BIB). </li>
              <li>Reach the Marathon Venue on 29th Oct 2023 at given reporting time. </li>
              <li>You shall receive the medal and certificate after finishing the run. </li>
              <li>Online certificate shall be generated from the Certificate Page of the website. </li>
              <li>Voila! You did it, Runveer.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionTwo;
