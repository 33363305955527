import React, { useEffect, useState } from "react";
import img from "../imgs/8.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CountUp from "react-countup";
import ShowErrorMessage, {
  ShowSuccess,
} from "../Components/CustomComponents/ShowMessages";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import moment from "moment";
import CountdownTimer from "../Components/CustomComponents/CountDownTimer";
import { Link, useNavigate } from "react-router-dom";

// const StateAndCityComponent = ({ countryCode = 'IN', cityCode = 'TG' }) => {

// const baseUrl="https://infantary.vercel.app";
// const baseUrl="${baseUrl}";
// const baseUrl = "http://localhost:5000";
// const baseUrl = "https://api.dotdx.in";
const baseUrl = "https://monkfish-app-zxijf.ondigitalocean.app";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function Home() {
  const navigate = useNavigate();

  const stateData = State.getStatesOfCountry("IN");
  const [state, setState] = useState("");
  const [modal, setModal] = useState(false);
  const [virtual, setVirtual] = useState(false);
  const [virtual1, setVirtual1] = useState(false);
  const cityData = City.getCitiesOfState("IN", state);

  var initialValue = {
    name: "",
    email: "",
    // aadhar: "",
    dob: "",
    gender: "",
    address: "",
    age: "",
    country: "",
    category: "",
    runnerCategory: "",
    bloodGroup: "",
    tShirtSize: "",
    bibCollection: "",
  };
  var initialValueOne = {
    name: "",
    email: "",
    // aadhar: "",
    dob: "",
    gender: "",
    address: "",
    age: "",
    country: "",
    category: "",
    runnerCategory: "",
    bloodGroup: "",
    tShirtSize: "",
    bibCollection: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [number, setNumber] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCity] = useState("");

  const [value1, setValue1] = useState({
    name: '',
    phone: '',
    email: '',
    dob: '',
    gender: '',
    country: '',
    state: '',
    city: '',
    address: '',
    pincode: '',
    raceCategory: '',
    runnerCategory: '',
    tShirt: '',
    medal: '',
    countryCode: ''
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loadFlag, setLoadFlag] = useState(false);

  useEffect(() => {
    let t = Country.getAllCountries();
    // console.log(t);
    setCountries(t);
  }, []);

  const handleChange1 = (e, type = "") => {
    if (e.target.name === "medal") {
      if (e.target.value === "true") {
        document.getElementById('is-medal').style.display = 'block';
      }
      else {
        document.getElementById('is-medal').style.display = 'none';
      }
    }
    if (type === "country") {
      let states = State.getStatesOfCountry(e.target.value);
      // console.log(states);
      setStates(states);
      // setValue1({...value1, [e.target.name]: countries.find(x=>x.isoCode===e.target.value).name});
      // setValue1({...value1, [e.target.name]: e.target.value});
    }
    else if (type === "state") {
      let t = Country.getAllCountries();
      let cities = City.getCitiesOfState(value1.country, e.target.value);
      // console.log(cities);
      setCities(cities);
      // setValue1({...value1, [e.target.name]: states.find(x=>x.isoCode===e.target.value).name});
    }
    setValue1({ ...value1, [e.target.name]: e.target.value });
  };

  const displayRazorpay3 = async (e) => {
    e.preventDefault();

    // console.log(value1);
    // console.log(formDataOne);
    // console.log(stateName);
    // console.log(cityName);
    // console.log(pinCode);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    try {
      const result = await axios.post(`${baseUrl}/api/user/payment/order`, {
        amount: 1999.0,
        currency: "INR",
        receipt: "650296adb56eaea71ee8a8d2",
        notes: ["PAYMENT"],
        mode: "international",
        name: value1.name,
        mobile: `${value1.countryCode}${value1.phone}`,
        email: value1.email,
        dob: value1.dob,
        category: value1.raceCategory,
        runnerCategory: value1.runnerCategory,
        bloodGroup: "",
        tShirtSize: value1.tShirt,
        bibCollection: "",
        address: {
          addressLine2: value1.address,
          city: value1.city,
          state: states?.find(x => x.isoCode === value1.state)?.name,
          postalCode: value1.pincode,
          country: countries?.find(x => x.isoCode === value1.country)?.name,
        },
        wantMedal: value1.medal,
        medalAddress: {
          addressLine2: formDataOne.address,
          city: cityName,
          state: stateName,
          postalCode: pinCode,
          country: formDataOne.country,
        }
      });

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      const { amount, id: order_id, currency } = result.data.data.order;

      const options = {
        key: "rzp_live_hX3wH4bK4T72fG",
        amount: amount.toString(),
        currency: currency,
        name: "Dot dx designs.",
        description: "Transaction",
        // image: { logo },
        order_id: order_id,

        handler: async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            // razorpayOrderId: response.razorpay_order_id,
            receipt: "650296adb56eaea71ee8a8d2",
            razorpaySignature: response.razorpay_signature,
            registrationID: result.data.data.registrationID
          };
          setLoadFlag(true);
          const result1 = await axios.post(`${baseUrl}/api/user/payment/verification`, data);

          // console.log(result);

          if (result1.data.statusCode === 200) {
            alert("Payment successfull! Verification mail sent successfull");
            setSuccessMessage("Payment successfull! Verification mail sent successfull");
            setFormData(initialValue);
            setValue1({
              name: '',
              phone: '',
              email: '',
              dob: '',
              gender: '',
              country: '',
              state: '',
              city: '',
              address: '',
              pincode: '',
              raceCategory: '',
              runnerCategory: '',
              tShirt: '',
              medal: ''
            });
            setErrorMessage("");
            setVirtual1(false);
            setTimeout(() => {
              setSuccessMessage("");
            }, 7000);

            // try {
            //   var raw = {
            //     mode: "international",
            //     name: value1.name,
            //     mobile: `${value1.countryCode}${value1.phone}`,
            //     email: value1.email,
            //     dob: value1.dob,
            //     category: value1.raceCategory,
            //     runnerCategory: value1.runnerCategory,
            //     bloodGroup: "",
            //     tShirtSize: value1.tShirt,
            //     bibCollection: "",
            //     address: {
            //       addressLine2: value1.address,
            //       city: value1.city,
            //       state: states?.find(x => x.isoCode === value1.state)?.name,
            //       postalCode: value1.pincode,
            //       country: countries?.find(x => x.isoCode === value1.country)?.name,
            //     },
            //     wantMedal: value1.medal,
            //     medalAddress: {
            //       addressLine2: formDataOne.address,
            //       city: cityName,
            //       state: stateName,
            //       postalCode: pinCode,
            //       country: formDataOne.country,
            //     }
            //   };

            //   const res = await axios({
            //     method: "post",
            //     url: `${baseUrl}/api/user/registration`,
            //     data: raw,
            //     headers: {
            //       "Content-Type": "application/json",
            //     },
            //   });
            //   // console.log(res);

            //   if (res?.status === 200) {
            //     setSuccessMessage(res?.data?.message);
            //     setFormData(initialValue);
            //     setValue1({
            //       name: '',
            //       phone: '',
            //       email: '',
            //       dob: '',
            //       gender: '',
            //       country: '',
            //       state: '',
            //       city: '',
            //       address: '',
            //       pincode: '',
            //       raceCategory: '',
            //       runnerCategory: '',
            //       tShirt: '',
            //       medal: ''
            //     });
            //     setErrorMessage("");
            //     setVirtual1(false);
            //     setTimeout(() => {
            //       setSuccessMessage("");
            //     }, 7000);
            //   } else {
            //     setErrorMessage(res?.error?.message);
            //     setTimeout(() => {
            //       setErrorMessage("");
            //       setSuccessMessage("");
            //     }, 3000);
            //     setFormData(initialValueOne);
            //   }
            // } catch (e) { }
          }
          else {
            alert("Something went wrong");
          }
          setLoadFlag(false);
        },
        prefill: {
          name: "Dot dx designs",
          email: value1.email,
          contact: value1.phone,
        },
        notes: {
          address: "206, Ronak tower, Anand Bazaar Square, Khajrana Main road",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const handleChange = (e,) => {
    const { name, value } = e.target;
    console.log(name, value, "<<< this i sname and value");
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNumber(value);
  };

  async function displayRazorpay(e) {
    e.preventDefault();

    const {
      name,
      email,
      dob,
      gender,
      category,
      runnerCategory,
      bloodGroup,
      tShirtSize,
      bibCollection,
    } = formData;

    if (name.trim() === "") return setErrorMessage("name is Required");
    if (number === "") return setErrorMessage("mobile Number is Required");
    if (email === "") return setErrorMessage("email is required");
    // if (aadhar === "") return setErrorMessage("aadhar is required");
    if (dob === "") return setErrorMessage("dob is required");
    if (gender === "") return setErrorMessage("gender is required");
    // if (age === "") return setErrorMessage("age is required");
    if (category === "") return setErrorMessage("category is required");
    if (runnerCategory === "")
      return setErrorMessage("runnerCategory is required");
    if (bloodGroup === "") return setErrorMessage("bloodGroup is required");
    if (tShirtSize === "") return setErrorMessage("tShirtSize is required");
    if (bibCollection === "")
      return setErrorMessage("bibCollection is required");

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    try {
      // creating a new order
      const result = await axios.post(`${baseUrl}/api/user/payment/order`, {
        amount: category === "5KM (Without Timing Chip)" || category === "10KM (Without Timing Chip)" ? 500.0 : 700.0,
        currency: "INR",
        receipt: "650296adb56eaea71ee8a8d2",
        notes: ["PAYMENT"],
        mode: "offline",
        name: formData.name,
        mobile: '91' + number,
        email: formData.email,
        // aadhar: formData.aadhar,
        dob: formData.dob,
        category: formData.category,
        runnerCategory: formData.runnerCategory,
        bloodGroup: formData.bloodGroup,
        tShirtSize: formData.tShirtSize,
        bibCollection: formData.bibCollection,
      });
      // console.log(result);

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      // Getting the order details back
      const { amount, id: order_id, currency } = result.data.data.order;

      const options = {
        key: "rzp_live_hX3wH4bK4T72fG",
        amount: amount.toString(),
        currency: currency,
        name: "Dot dx designs.",
        description: "Transaction",
        // image: { logo },
        order_id: order_id,
        handler: async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            // razorpayOrderId: response.razorpay_order_id,
            receipt: "650296adb56eaea71ee8a8d2",
            razorpaySignature: response.razorpay_signature,
            registrationID: result.data.data.registrationID
          };

          setLoadFlag(true);

          const result1 = await axios.post(`${baseUrl}/api/user/payment/verification`, data);

          // console.log(result);

          if (result1.data.statusCode === 200) {
            alert("Payment successfull! Verification mail sent successfull");

            setSuccessMessage("Payment successfull! Verification mail sent successfull");
            setFormData(initialValue);
            setErrorMessage("");
            setModal(false);
            setTimeout(() => {
              setSuccessMessage("");
            }, 3000);

            // try {
            //   var raw = JSON.stringify({
            //     mode: "offline",
            //     name: formData.name,
            //     mobile: '91' + number,
            //     email: formData.email,
            //     // aadhar: formData.aadhar,
            //     dob: formData.dob,
            //     category: formData.category,
            //     runnerCategory: formData.runnerCategory,
            //     bloodGroup: formData.bloodGroup,
            //     tShirtSize: formData.tShirtSize,
            //     bibCollection: formData.bibCollection,
            //   });

            //   const res = await axios({
            //     method: "post",
            //     url: `${baseUrl}/api/user/registration`,
            //     data: raw,
            //     headers: {
            //       "Content-Type": "application/json",
            //     },
            //   });

            //   // console.log(res.data);
            //   if (res?.status === 200) {
            //     setSuccessMessage(res?.data?.message);
            //     setFormData(initialValue);
            //     setErrorMessage("");
            //     setModal(false);
            //     setTimeout(() => {
            //       setSuccessMessage("");
            //     }, 7000);

            //   } else {
            //     setErrorMessage(res?.error?.message);
            //     setTimeout(() => {
            //       setErrorMessage("");
            //       setSuccessMessage("");
            //     }, 3000);
            //     setFormData(initialValue);
            //   }
            // } catch (e) { }
          }
          else {
            alert("Something went wrong");
          }

          setLoadFlag(false);
        },

        prefill: {
          name: "Dot dx designs",
          email: email,
          contact: number,
        },

        notes: {
          address: "206, Ronak tower, Anand Bazaar Square, Khajrana Main road",
        },

        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  async function RegisterOne() {
    const {
      name,
      email,
      dob,
      gender,
      category,
      runnerCategory,
      bloodGroup,
      tShirtSize,
      bibCollection,
    } = formData;
    if (name.trim() === "") return setErrorMessage("name is Required");
    if (number === "") return setErrorMessage("mobile Number is Required");
    if (email === "") return setErrorMessage("email is required");
    // if (aadhar === "") return setErrorMessage("aadhar is required");
    if (dob === "") return setErrorMessage("dob is required");
    if (gender === "") return setErrorMessage("gender is required");
    // if (age === "") return setErrorMessage("age is required");
    if (category === "") return setErrorMessage("category is required");
    if (runnerCategory === "")
      return setErrorMessage("runnerCategory is required");
    if (bloodGroup === "") return setErrorMessage("bloodGroup is required");
    if (tShirtSize === "") return setErrorMessage("tShirtSize is required");
    if (bibCollection === "")
      return setErrorMessage("bibCollection is required");

    try {
      var raw = JSON.stringify({
        mode: "offline",
        name: formData.name,
        mobile: number,
        email: formData.email,
        // aadhar: formData.aadhar,
        dob: formData.dob,
        category: formData.category,
        runnerCategory: formData.runnerCategory,
        bloodGroup: formData.bloodGroup,
        tShirtSize: formData.tShirtSize,
        bibCollection: formData.bibCollection,
      });
      const res = await axios({
        method: "post",
        url: `${baseUrl}/api/user/registration`,
        data: raw,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res?.status === 200) {
        // setSuccessMessage(res?.data?.message);
        // setFormData(initialValue);
        // setErrorMessage("");
        // setTimeout(() => {
        //   setSuccessMessage("");
        // }, 7000);

      } else {
        setErrorMessage(res?.error?.message);
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 3000);
        setFormData(initialValue);
      }
    } catch (e) { }
  }

  // Virtual Form Code
  const [formDataOne, setFormDataOne] = useState(initialValueOne);
  const handleChangeOne = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "<<< this i sname and value");
    setFormDataOne({ ...formDataOne, [name]: value });
  };

  const handleChangeState = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const StateName = el.getAttribute("id");
    const stateId = e.target.value;
    setState(stateId);
    setStateName(StateName);
  };

  const handleChangePinCode = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPinCode(value);
  };

  async function displayRazorpay2(e) {
    e.preventDefault();

    const {
      name,
      email,
      country,
      dob,
      gender,
      address,
      category,
      runnerCategory,
      bloodGroup,
      tShirtSize,
      bibCollection,
    } = formDataOne;

    if (name.trim() === "") return setErrorMessage("name is Required");
    if (number === "") return setErrorMessage("mobile Number is Required");
    if (email === "") return setErrorMessage("email is required");
    if (dob === "") return setErrorMessage("dob is required");
    if (gender === "") return setErrorMessage("gender is required");
    if (country === "") return setErrorMessage("country is required");
    if (stateName === "") return setErrorMessage("state is required");
    if (cityName === "") return setErrorMessage("City is required");
    if (address === "") return setErrorMessage("Address is required");
    if (pinCode === "") return setErrorMessage("Pin code is required");
    // if (age === "") return setErrorMessage("age is required");
    if (category === "") return setErrorMessage("category is required");
    if (runnerCategory === "")
      return setErrorMessage("runnerCategory is required");
    // if (bloodGroup === "") return setErrorMessage("bloodGroup is required");
    if (tShirtSize === "") return setErrorMessage("tShirtSize is required");
    // if (bibCollection === "")
    //   return setErrorMessage("bibCollection is required");

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    try {
      // creating a new order
      const result = await axios.post(`${baseUrl}/api/user/payment/order`, {
        amount: 700.0,
        currency: "INR",
        receipt: "650296adb56eaea71ee8a8d2",
        notes: ["PAYMENT"],
        mode: "virtual",
        name: formDataOne.name,
        mobile: '91' + number,
        email: formDataOne.email,
        dob: formDataOne.dob,
        category: formDataOne.category,
        runnerCategory: formDataOne.runnerCategory,
        bloodGroup: formDataOne.bloodGroup,
        tShirtSize: formDataOne.tShirtSize,
        bibCollection: formDataOne.bibCollection,
        address: {
          addressLine2: formDataOne.address,
          city: cityName,
          state: stateName,
          postalCode: pinCode,
          country: formDataOne.country,
        }
      });
      // console.log(result);

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      // Getting the order details back
      const { amount, id: order_id, currency } = result.data.data.order;

      const options = {
        key: "rzp_live_hX3wH4bK4T72fG",
        amount: amount.toString(),
        currency: currency,
        name: "Dot dx designs.",
        description: "Transaction",
        // image: { logo },
        order_id: order_id,
        handler: async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            // razorpayOrderId: response.razorpay_order_id,
            receipt: "650296adb56eaea71ee8a8d2",
            razorpaySignature: response.razorpay_signature,
            registrationID: result.data.data.registrationID
          };
          setLoadFlag(true);
          const result1 = await axios.post(`${baseUrl}/api/user/payment/verification`, data);

          // console.log(result);

          if (result1.data.statusCode === 200) {
            alert("Payment successfull! Verification mail sent successfull");
            setSuccessMessage("Payment successfull! Verification mail sent successfull");
            setFormData(initialValue);
            setErrorMessage("");
            setVirtual(false);
            setTimeout(() => {
              setSuccessMessage("");
            }, 7000);

            // try {
            //   var raw = {
            //     mode: "virtual",
            //     name: formDataOne.name,
            //     mobile: '91' + number,
            //     email: formDataOne.email,
            //     dob: formDataOne.dob,
            //     category: formDataOne.category,
            //     runnerCategory: formDataOne.runnerCategory,
            //     bloodGroup: formDataOne.bloodGroup,
            //     tShirtSize: formDataOne.tShirtSize,
            //     bibCollection: formDataOne.bibCollection,
            //     address: {
            //       addressLine2: formDataOne.address,
            //       city: cityName,
            //       state: stateName,
            //       postalCode: pinCode,
            //       country: formDataOne.country,
            //     },
            //   };
            //   const res = await axios({
            //     method: "post",
            //     url: `${baseUrl}/api/user/registration`,
            //     data: raw,
            //     headers: {
            //       "Content-Type": "application/json",
            //     },
            //   });
            //   if (res?.status === 200) {
            //     setSuccessMessage(res?.data?.message);
            //     setFormData(initialValue);
            //     setErrorMessage("");
            //     setVirtual(false);
            //     setTimeout(() => {
            //       setSuccessMessage("");
            //     }, 7000);
            //   } else {
            //     setErrorMessage(res?.error?.message);
            //     setTimeout(() => {
            //       setErrorMessage("");
            //       setSuccessMessage("");
            //     }, 3000);
            //     setFormData(initialValueOne);
            //   }
            // } catch (e) { }
          }
          else {
            alert("Something went wrong");
          }
          setLoadFlag(false);
        },
        prefill: {
          name: "Dot dx designs",
          email: formDataOne.email,
          contact: number,
        },
        notes: {
          address: "206, Ronak tower, Anand Bazaar Square, Khajrana Main road",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert(error.response.data.message);
    }
  }

  async function RegisterTwo() {
    const {
      name,
      email,
      country,
      dob,
      gender,
      address,
      category,
      runnerCategory,
      bloodGroup,
      tShirtSize,
      bibCollection,
    } = formDataOne;
    if (name.trim() === "") return setErrorMessage("name is Required");
    if (number === "") return setErrorMessage("mobile Number is Required");
    if (email === "") return setErrorMessage("email is required");
    if (dob === "") return setErrorMessage("dob is required");
    if (gender === "") return setErrorMessage("gender is required");
    if (country === "") return setErrorMessage("country is required");
    if (stateName === "") return setErrorMessage("state is required");
    if (cityName === "") return setErrorMessage("City is required");
    if (address === "") return setErrorMessage("Address is required");
    if (pinCode === "") return setErrorMessage("Pin code is required");
    // if (age === "") return setErrorMessage("age is required");
    if (category === "") return setErrorMessage("category is required");
    if (runnerCategory === "")
      return setErrorMessage("runnerCategory is required");
    if (bloodGroup === "") return setErrorMessage("bloodGroup is required");
    if (tShirtSize === "") return setErrorMessage("tShirtSize is required");
    if (bibCollection === "")
      return setErrorMessage("bibCollection is required");
    try {
      var raw = {
        mode: "virtual",
        name: formDataOne.name,
        mobile: number,
        email: formDataOne.email,
        dob: formDataOne.dob,
        category: formDataOne.category,
        runnerCategory: formDataOne.runnerCategory,
        bloodGroup: formDataOne.bloodGroup,
        tShirtSize: formDataOne.tShirtSize,
        bibCollection: formDataOne.bibCollection,
        address: {
          addressLine2: formDataOne.address,
          city: cityName,
          state: stateName,
          postalCode: pinCode,
          country: formDataOne.country,
        },
      };
      const res = await axios({
        method: "post",
        url: `${baseUrl}/api/user/registration`,
        data: raw,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res?.status === 200) {
        setSuccessMessage(res?.data?.message);
        setFormData(initialValue);
        setErrorMessage("");
        setTimeout(() => {
          setSuccessMessage("");
        }, 7000);
      } else {
        setErrorMessage(res?.error?.message);
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 3000);
        setFormData(initialValueOne);
      }
    } catch (e) { }
  }

  const THREE_DAYS_IN_MS = new Date(2023, 9, 29, 0, 0, 0).getTime();
  const NOW_IN_MS = new Date().getTime();

  // const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  const dateTimeAfterThreeDays = (THREE_DAYS_IN_MS - NOW_IN_MS) + NOW_IN_MS;
  // console.log(dateTimeAfterThreeDays);

  return (
    <>
      <nav className="navbar">
        <div className="nav1">
          <div className="nav11">
            <p className="counter">
              <CountUp start={0} end={5000} duration={3} delay={0} /> +
              Runveer's Joined
            </p>
          </div>
          <div className="nav11">
            <p>Home</p>
            <p onClick={()=>{
              navigate('/download-certificate');
            }}>Certificate</p>
            <p className="cursor=pointer" onClick={() => {
              navigate('/submit-run');
            }}>Submit Your Run</p>
          </div>
        </div>
      </nav>

      <div className="Home">
        <div className="home-banner">
          <img src={'/images/1.png'} />
          {/* <br /> */}

          <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalHeader toggle={() => setModal(!modal)}>
              <p className="Runwithus">Run with us</p>
            </ModalHeader>

            <div className="container">
              {errorMessage != "" && (
                <ShowErrorMessage message={errorMessage} />
              )}
              {successMessage != "" && <ShowSuccess message={successMessage} />}

              <ModalBody className="modelbody">
                {loadFlag && <div className="global-loader">Please wait while your payment is processing ...</div>}
                <form onSubmit={displayRazorpay}>
                  <input
                    placeholder="Name"
                    value={formData.name}
                    name="name"
                    onChange={handleChange}
                    className="top-inputs"
                  />

                  <input
                    placeholder="Mobile Number"
                    value={number}
                    name="mobile"
                    maxLength={10}
                    onChange={handleChangeNumber}
                    className="top-inputs"
                  />

                  <input
                    placeholder="Email ID"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    className="top-inputs"
                  />
                  {/* <input
                    placeholder="aadhar"
                    value={formData.aadhar}
                    name="aadhar"
                    onChange={handleChange}
                    className="top-inputs"
                  /> */}
                  <input
                    placeholder="DOB"
                    type="date"
                    className="top-inputs"
                    name="dob"
                    onChange={handleChange}
                    value={formData.dob}
                  />
                  {/* <input
                    placeholder="bibCollection"
                    type="text"
                    className="top-inputs"
                    name="bibCollection"
                    onChange={handleChange}
                    value={formData.bibCollection}
                  /> */}
                  {/* <input
                    placeholder="Age"
                    type="text"
                    className="top-inputs"
                    name="age"
                    onChange={handleChange}
                    value={formData.age}
                  /> */}
                  <div className="selections">
                    <label htmlFor="gender">Select Gender:</label>
                    <select name="gender" onChange={handleChange} id="gender">
                      <option>Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="bibCollection">
                      Select BIB Collection Point:
                    </label>
                    <select
                      name="bibCollection"
                      onChange={handleChange}
                      id="bibCollection"
                    >
                      <option>Select Collection</option>
                      <option value="Mhow">Mhow</option>
                      <option value="Indore">Indore</option>
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="category">Race Category :</label>
                    <select name="category" onChange={handleChange} id="category">
                      <option>Select Race</option>
                      <option value="5KM">5KM</option>
                      <option value="10KM">10KM</option>
                      <option value="21KM">21KM</option>
                      <option value="5KM (Without Timing Chip)">
                        5KM (Without Timing Chip)
                      </option>
                      <option value="10KM (Without Timing Chip)">
                        10KM (Without Timing Chip)
                      </option>
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="runnerCategory">Runner Category :</label>
                    <select
                      name="runnerCategory"
                      onChange={handleChange}
                      id="runnerCategory"
                    >
                      <option>Select Runner Category</option>
                      <option value="Military">Military</option>
                      <option value="Non-military">Non-military</option>
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="bloodGroup">Blood Group:</label>
                    <select name="bloodGroup" onChange={handleChange}>
                      <option>Select Blood Group</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="tShirtSize">T Shirt Size:</label>
                    <select name="tShirtSize" onChange={handleChange}>
                      <option>Select T-Shirt Size</option>
                      <optgroup label="Adult Sizes">
                        <option value="Adult - S">Adult - S</option>
                        <option value="Adult - M">Adult - M</option>
                        <option value="Adult - L">Adult - L</option>
                        <option value="Adult - XL">Adult - XL</option>
                        <option value="Adult - XXL">Adult - XXL</option>
                        <option value="Adult - XXXL">Adult - XXXL</option>
                      </optgroup>
                      <optgroup label="Children Sizes">
                        <option value="Children - 22">Children - 22</option>
                        <option value="Children - 24">Children - 24</option>
                        <option value="Children - 26">Children - 26</option>
                        <option value="Children - 28">Children - 28</option>
                        <option value="Children - 30">Children - 30</option>
                      </optgroup>
                    </select>
                  </div>

                  <div className="checks">
                    <input type="checkbox" id="myCheckbox" required />
                    <label style={{ marginLeft: "15px" }} htmlFor="myCheckbox">
                      <Link to={'/terms'}>Terms and Conditions</Link>
                    </label>
                  </div>
                  <div className="checks">
                    <input type="checkbox" id="myCheckbox" required />
                    <label style={{ marginLeft: "15px" }} htmlFor="myCheckbox">
                      I am Medically fit
                    </label>
                  </div>

                  <b>Registration Fee for 5K,10K,21K is ₹700</b>
                  <br />
                  <b>Registration Fee for 5K,10K (Without Timing Chip) is ₹500</b>
                  <br />

                  {/* <button className="registor" onClick={RegisterOne}> */}
                  <div style={{ textAlign: 'center' }}>
                    <button type="submit" className="registor">
                      Register
                    </button>
                  </div>
                </form>
              </ModalBody>
            </div>
          </Modal>

          <Modal size="lg" isOpen={virtual} toggle={() => setVirtual(!virtual)}>
            <ModalHeader toggle={() => setVirtual(!virtual)}>
              <p className="Runwithus">Run with us</p>
            </ModalHeader>

            <div className="container">
              {errorMessage != "" && (
                <ShowErrorMessage message={errorMessage} />
              )}
              {successMessage != "" && <ShowSuccess message={successMessage} />}

              <ModalBody className="modelbody">
                {loadFlag && <div className="global-loader">Please wait while your payment is processing ...</div>}
                <form onSubmit={displayRazorpay2}>
                  <input
                    placeholder="Name"
                    value={formDataOne.name}
                    name="name"
                    onChange={handleChangeOne}
                    className="top-inputs"
                  />
                  <input
                    placeholder="Mobile Number"
                    value={number}
                    maxLength={10}
                    name="mobile"
                    onChange={handleChangeNumber}
                    className="top-inputs"
                  />
                  <input
                    placeholder="Email ID"
                    value={formDataOne.email}
                    name="email"
                    onChange={handleChangeOne}
                    className="top-inputs"
                  />
                  {/* <input
                    placeholder="aadhar"
                    value={formDataOne.aadhar}
                    name="aadhar"
                    onChange={handleChange}
                    className="top-inputs"
                  /> */}
                  <input
                    placeholder="DOB"
                    type="date"
                    className="top-inputs"
                    name="dob"
                    onChange={handleChangeOne}
                    value={formDataOne.dob}
                  />
                  {/* <input
                    placeholder="bibCollection"
                    type="text"
                    className="top-inputs"
                    name="bibCollection"
                    onChange={handleChange}
                    value={formDataOne.bibCollection}
                  /> */}
                  {/* <input
                    placeholder="Age"
                    type="text"
                    className="top-inputs"
                    name="age"
                    onChange={handleChange}
                    value={formDataOne.age}
                  /> */}

                  <div className="selections">
                    <label htmlFor="gender">Select Gender:</label>
                    <select name="gender" onChange={handleChangeOne} id="gender">
                      <option>Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="state">Country:</label>
                    <select
                      id="country"
                      onChange={handleChangeOne}
                      name="country"
                    >
                      <option>Select Country</option>
                      <option value="India">India</option>
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="state">State:</label>
                    <select
                      id="country"
                      name="country"
                      onChange={handleChangeState}
                    >
                      <option>Select State</option>
                      {stateData.map((element, index) => {
                        return (
                          <option
                            key={index}
                            id={element.name}
                            value={element.isoCode}
                          >
                            {element.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="city">City:</label>
                    <select
                      id="country"
                      name="country"
                      onChange={(e) => setCity(e.target.value)}
                    >
                      <option>Select City</option>
                      {cityData.map((element, index) => {
                        return (
                          <option key={index} value={element.name}>
                            {element.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="address">Address:</label>
                    <input
                      type="text"
                      id="address"
                      value={formDataOne.address}
                      onChange={handleChangeOne}
                      name="address"
                      required
                    />
                  </div>
                  <div className="selections">
                    <label htmlFor="state">Pincode:</label>
                    <input
                      type="text"
                      maxLength={6}
                      value={pinCode}
                      onChange={handleChangePinCode}
                      id="state"
                      name="state"
                      required
                    />
                  </div>
                  {/* <div className="selections">
                    <label htmlFor="bibCollection">
                      Select BIB Collection Point:
                    </label>
                    <select
                      name="bibCollection"
                      onChange={handleChangeOne}
                      id="bibCollection"
                    >
                      <option>Select Collection</option>
                      <option value="Mhow">Mhow</option>
                      <option value="Indore">Indore</option>
                    </select>
                  </div> */}
                  <div className="selections">
                    <label htmlFor="category">Race Category :</label>
                    <select
                      name="category"
                      onChange={handleChangeOne}
                      id="category"
                    >
                      <option>Select Race</option>
                      <option value="5KM">5KM</option>
                      <option value="10KM">10KM</option>
                      <option value="21KM">21KM</option>
                      <option value="42KM">42KM</option>
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlhtmlFor="runnerCategory">Runner Category :</label>
                    <select
                      name="runnerCategory"
                      onChange={handleChangeOne}
                      id="runnerCategory"
                    >
                      <option>Select Runner Category</option>
                      <option value="Military">Military</option>
                      <option value="Non-military">Non-military</option>
                    </select>
                  </div>
                  {/* <div className="selections">
                    <label htmlFor="bloodGroup">Blood Group:</label>
                    <select name="bloodGroup" onChange={handleChangeOne}>
                      <option>Select Blood Group</option>
                      <option value="A+">A+</option>
                      <option value="B-">B-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                    </select>
                  </div> */}
                  <div className="selections">
                    <label htmlFor="tShirtSize">T Shirt Size:</label>
                    <select name="tShirtSize" onChange={handleChangeOne}>
                      <option>Select T-Shirt Size</option>
                      <optgroup label="Adult Sizes">
                        <option value="Adult - S">Adult - S</option>
                        <option value="Adult - M">Adult - M</option>
                        <option value="Adult - L">Adult - L</option>
                        <option value="Adult - XL">Adult - XL</option>
                        <option value="Adult - XXL">Adult - XXL</option>
                        <option value="Adult - XXXL">Adult - XXXL</option>
                      </optgroup>
                      <optgroup label="Children Sizes">
                        <option value="Children - 22">Children - 22</option>
                        <option value="Children - 24">Children - 24</option>
                        <option value="Children - 26">Children - 26</option>
                        <option value="Children - 28">Children - 28</option>
                        <option value="Children - 30">Children - 30</option>
                      </optgroup>
                    </select>
                  </div>

                  <div className="checks">
                    <input type="checkbox" id="myCheckbox" required />
                    <label style={{ marginLeft: "15px" }} htmlFor="myCheckbox">
                      <Link to={'/terms1'}>Terms and Conditions</Link>
                    </label>
                  </div>
                  <div className="checks">
                    <input type="checkbox" id="myCheckbox" required />
                    <label style={{ marginLeft: "15px" }} htmlFor="myCheckbox">
                      I am Medically fit
                    </label>
                  </div>

                  <b>Your total charge is ₹700 including delivery charges & registration fee.</b>

                  <div style={{ textAlign: 'center' }}>
                    <button type="submit" className="registor">
                      Register
                    </button>
                  </div>
                </form>
              </ModalBody>
            </div>
          </Modal>

          <Modal size="lg" isOpen={virtual1} toggle={() => setVirtual1(!virtual1)}>
            <ModalHeader toggle={() => setVirtual1(!virtual1)}>
              <p className="Runwithus">Run with us</p>
            </ModalHeader>

            <div className="container">
              {errorMessage != "" && (
                <ShowErrorMessage message={errorMessage} />
              )}
              {successMessage != "" && <ShowSuccess message={successMessage} />}

              <ModalBody className="modelbody">
                {loadFlag && <div className="global-loader">Please wait while your payment is processing ...</div>}
                <form onSubmit={displayRazorpay3}>
                  <input
                    placeholder="Name"
                    value={value1.name}
                    name="name"
                    onChange={handleChange1}
                    className="top-inputs"
                  />
                  <div className="flex" style={{ width: "93%" }}>
                    <input
                      placeholder="61"
                      value={value1.countryCode}
                      name="countryCode"
                      onChange={handleChange1}
                      className="top-inputs"
                      style={{ width: '5rem' }}
                    />
                    <input
                      placeholder="Mobile Number"
                      value={value1.phone}
                      maxLength={10}
                      name="phone"
                      onChange={handleChange1}
                      className="top-inputs"
                      style={{ width: '100%' }}
                    />
                  </div>
                  <input
                    placeholder="Email ID"
                    value={value1.email}
                    name="email"
                    onChange={handleChange1}
                    className="top-inputs"
                  />
                  {/* <input
                    placeholder="aadhar"
                    value={formDataOne.aadhar}
                    name="aadhar"
                    onChange={handleChange}
                    className="top-inputs"
                  /> */}
                  <input
                    placeholder="DOB"
                    type="date"
                    className="top-inputs"
                    name="dob"
                    onChange={handleChange1}
                    value={value1.dob}
                  />
                  {/* <input
                    placeholder="bibCollection"
                    type="text"
                    className="top-inputs"
                    name="bibCollection"
                    onChange={handleChange}
                    value={formDataOne.bibCollection}
                  /> */}
                  {/* <input
                    placeholder="Age"
                    type="text"
                    className="top-inputs"
                    name="age"
                    onChange={handleChange}
                    value={formDataOne.age}
                  /> */}

                  <div className="selections">
                    <label htmlFor="gender">Select Gender:</label>
                    <select name="gender" onChange={handleChange1} id="gender" value={value1.gender}>
                      <option>Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="state">Country:</label>
                    <select
                      id="country"
                      onChange={(e) => {
                        handleChange1(e, "country");
                      }}
                      value={value1.country}
                      name="country"
                    >
                      <option>Select Country</option>
                      {countries.map((e, index) => {
                        return (
                          <option key={index} value={e.isoCode}>{e.name}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="state">State:</label>
                    <select
                      id="state"
                      name="state"
                      onChange={(e) => {
                        handleChange1(e, "state");
                      }}
                      value={value1.state}
                    >
                      <option>Select State</option>
                      {states.map((element, index) => {
                        return (
                          <option
                            key={index}
                            id={element.name}
                            value={element.isoCode}
                          >
                            {element.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="selections">
                    <label htmlFor="city">City:</label>
                    <select
                      id="city"
                      name="city"
                      onChange={handleChange1}
                      value={value1.city}
                    >
                      <option>Select City</option>
                      {cities.map((element, index) => {
                        return (
                          <option key={index} value={element.name}>
                            {element.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="selections">
                    <label htmlFor="address">Address:</label>
                    <input
                      type="text"
                      id="address"
                      value={value1.address}
                      onChange={handleChange1}
                      name="address"
                      required
                    />
                  </div>

                  <div className="selections">
                    <label htmlFor="state">Pincode:</label>
                    <input
                      type="text"
                      maxLength={6}
                      value={value1.pincode}
                      onChange={handleChange1}
                      id="pincode"
                      name="pincode"
                      required
                    />
                  </div>

                  <div className="selections">
                    <p>Due to customs clearance medal will be delivered to local address(India) </p>
                  </div>

                  <div className="selections">
                    <label htmlFor="medal">Medal (Please type your India's Address) :</label>
                    <select
                      name="medal"
                      onChange={handleChange1}
                      id="medal"
                      value={value1.medal}
                    >
                      <option>Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>

                  <div id="is-medal" style={{ display: 'none' }}>
                    <div className="selections">
                      <label htmlFor="state">Country:</label>
                      <select
                        id="country"
                        onChange={handleChangeOne}
                        name="country"
                      >
                        <option>Select Country</option>
                        <option value="India">India</option>
                      </select>
                    </div>
                    <div className="selections">
                      <label htmlFor="state">State:</label>
                      <select
                        id="country"
                        name="country"
                        onChange={handleChangeState}
                      >
                        <option>Select State</option>
                        {stateData.map((element, index) => {
                          return (
                            <option
                              key={index}
                              id={element.name}
                              value={element.isoCode}
                            >
                              {element.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="selections">
                      <label htmlFor="city1">City:</label>
                      <select
                        id="city1"
                        name="city1"
                        onChange={(e) => setCity(e.target.value)}
                      >
                        <option>Select City</option>
                        {cityData.map((element, index) => {
                          return (
                            <option key={index} value={element.name}>
                              {element.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="selections">
                      <label htmlFor="address1">Address:</label>
                      <input
                        type="text"
                        id="address1"
                        value={formDataOne.address}
                        // onChange={handleChangeOne}
                        onChange={(e) => {
                          setFormDataOne({ ...formDataOne, address: e.target.value });
                        }}
                        name="address1"
                      // required
                      />
                    </div>
                    <div className="selections">
                      <label htmlFor="pin1">Pincode:</label>
                      <input
                        type="text"
                        maxLength={6}
                        value={pinCode}
                        onChange={handleChangePinCode}
                        id="pin1"
                        name="pin1"
                      // required
                      />
                    </div>
                  </div>

                  {/* <div className="selections">
                    <label htmlFor="bibCollection">
                      Select BIB Collection Point:
                    </label>
                    <select
                      name="bibCollection"
                      onChange={handleChangeOne}
                      id="bibCollection"
                    >
                      <option>Select Collection</option>
                      <option value="Mhow">Mhow</option>
                      <option value="Indore">Indore</option>
                    </select>
                  </div> */}

                  <div className="selections">
                    <label htmlFor="raceCategory">Race Category :</label>
                    <select
                      name="raceCategory"
                      onChange={handleChange1}
                      id="raceCategory"
                      value={value1.raceCategory}
                    >
                      <option>Select Race</option>
                      <option value="5KM">5KM</option>
                      <option value="10KM">10KM</option>
                      <option value="21KM">21KM</option>
                      <option value="42KM">42KM</option>
                    </select>
                  </div>
                  {/* <div className="selections">
                    <label htmlhtmlFor="runnerCategory">Runner Category :</label>
                    <select
                      name="runnerCategory"
                      onChange={handleChange1}
                      id="runnerCategory"
                      value={value1.runnerCategory}
                    >
                      <option>Select Runner Category</option>
                      <option value="Military">Military</option>
                      <option value="Non-military">Non-military</option>
                    </select>
                  </div> */}
                  {/* <div className="selections">
                    <label htmlFor="bloodGroup">Blood Group:</label>
                    <select name="bloodGroup" onChange={handleChangeOne}>
                      <option>Select Blood Group</option>
                      <option value="A+">A+</option>
                      <option value="B-">B-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                    </select>
                  </div> */}

                  <div className="selections">
                    <label htmlFor="tShirt">T Shirt Size:</label>
                    <select name="tShirt" onChange={handleChange1} value={value1.tShirt} id="tShirt">
                      <option>Select T-Shirt Size</option>
                      <optgroup label="Adult Sizes">
                        <option value="Adult - S">Adult - S</option>
                        <option value="Adult - M">Adult - M</option>
                        <option value="Adult - L">Adult - L</option>
                        <option value="Adult - XL">Adult - XL</option>
                        <option value="Adult - XXL">Adult - XXL</option>
                        <option value="Adult - XXXL">Adult - XXXL</option>
                      </optgroup>
                      <optgroup label="Children Sizes">
                        <option value="Children - 22">Children - 22</option>
                        <option value="Children - 24">Children - 24</option>
                        <option value="Children - 26">Children - 26</option>
                        <option value="Children - 28">Children - 28</option>
                        <option value="Children - 30">Children - 30</option>
                      </optgroup>
                    </select>
                  </div>

                  <div className="checks">
                    <input type="checkbox" id="myCheckbox" required />
                    <label style={{ marginLeft: "15px" }} htmlFor="myCheckbox">
                      <Link to={'/terms2'}>Terms and Conditions</Link>
                    </label>
                  </div>
                  <div className="checks">
                    <input type="checkbox" id="myCheckbox" required />
                    <label style={{ marginLeft: "15px" }} htmlFor="myCheckbox">
                      I am Medically fit
                    </label>
                  </div>
                  <b>Your total charge is ₹1,999 including delivery charges & registration fee.</b>
                  <br />

                  <div style={{ textAlign: 'center' }}>
                    <button type="submit" className="registor">
                      Register
                    </button>
                  </div>
                </form>
              </ModalBody>
            </div>
          </Modal>

          {/* <div className="marathon-btns"> */}
            {/* <button onClick={() => setModal(true)}>Mhow Marathon</button> */}
            {/* <button onClick={() => setVirtual(true)}>Virtual Marathon</button> */}
            {/* <button onClick={() => setVirtual1(true)}>International Virtual Marathon</button> */}
          {/* </div> */}
        </div>
      </div>

      <div className="days">
        <CountdownTimer targetDate={dateTimeAfterThreeDays} />

        <div className="black-div">
          <p className="time">5km/10km/21km</p>
        </div>
      </div>
    </>
  );
};

export default Home;
