import React from 'react'
import CountUp from "react-countup";
import { useNavigate } from 'react-router-dom';

const Terms = () => {
    const navigate=useNavigate();

    return (
        <>
            <nav className="navbar">
                <div className="nav1">
                    <div className="nav11">
                        <p className="counter">
                            <CountUp start={0} end={100} duration={3} delay={0} /> +
                            Runveer's Joined
                        </p>
                    </div>
                </div>
            </nav>

            <div className="terms">
                <div className="terms1">
                    <div style={{fontSize: "23px", fontWeight: "600", marginBottom: "20px"}} className="terms11 text-center">
                        Terms and Condition
                    </div>
                    <div className="terms12">
                        <ul>
                            <li>Please choose the event category carefully, confirmed registrations are non-refundable, non-transferable and cannot be modified. Provide us with a secure email ID/mobile number that you can access regularly, since this will be our primary means of communication during the run up to the event.</li>
                            <li>I hereby consent to receiving information through SMS/Email on my registered number/email address</li>
                            <li>I hereby unconditionally authorize the Event Management and its Title Sponsor’s to send promotional mailers and sms including any service, products or offers.</li>
                            <li>I authorize the / the Event Manager and its Title Sponsor or their representatives /agents to make references and enquiries as may be deemed necessary in their discretion and to exchange, share or part with all the information or data as may be deemed necessary or appropriate with regard to the information furnished by me.</li>
                            <li>Users of email services that offer filtering/blocking of messages from unknown email addresses should add this email id runveersupport@gmail.com to their address list.</li>
                            <li>We will be sending regular updates to the mobile number you have provided in the registration form. This should not be treated as spam and you shall not take any action against our bulk sms / email service provider and / or the Event Manager and its Sponsors.</li>
                            <li>Any notice sent to the email address registered with the organizers shall be deemed as received by the runners.</li>
                            <li>Please fill out only those fields that are necessary for mailing purposes. Do not provide redundant data in multiple fields (i.e., do not list the same data for city, province and country), as this will only complicate our ability to contact you, if necessary.</li>
                            <li>You are aware that running / long distance running is an extreme sport and can be injurious to body and health. You take full responsibility for participating in the Runveer 5.0 - 2023 event and do not hold the organizing committee or any of its Sponsors, members or entities responsible for any injury or accident.</li>
                            <li>You shall consult your physician and undergo complete medical examination to assess your suitability to participate in the event.</li>
                            <li>You also assume all risks associated with participating in this event including, but not limited to, falls, contact with other participants, the effects of the weather, including high heat or humidity, traffic and the condition of the road, arson or terrorist threats and all other risks associated with a public event.</li>
                            <li>You agree that Runveer 5.0 - 2023 or any of its Sponsors shall not be liable for any loss, damage, illness or injury that might occur as a result of your participation in the event.</li>
                            <li>You agree to abide by the instructions provided by the organizers from time to time in the best interest of your health and event safety.</li>
                            <li>You also agree to stop running if instructed by the Race Director or the Medical Staff or by the Aid Station Volunteers.</li>
                            <li>You confirm that your name and media recordings taken during your participation may be used to publicize the event.</li>
                            <li>You may acknowledge and agree that your personal information can be stored and used by Runveer 5.0 - 2023, or its Title Sponsor or any other company in connection with the organization, promotion and administration of the event and for the compilation of statistical information.</li>
                            <li>You confirm that, in the event of adverse weather conditions, major incidents or threats on the day, any of the force majeure or restriction by authority, the organizers reserve the right to stop/cancel/postpone the event. You understand that confirmed registrations and merchandise orders are non-refundable, non-transferable and cannot be modified. The organizers reserve the right to reject any application without providing reasons. Any amount collected from rejected applications alone will be refunded in full (excluding bank charges wherever applicable).</li>
                            <li>Cancellations / Transfer of Tickets / Refund are not possible at all. Any requests received will not be eligible for a refund, under any circumstances.</li>
                            <li>Also, in case of occurrence of any unforeseeable circumstances / events / situations (even force majeure) due to any x-factor leading up to event cancellation, the fees paid for timing chip will be refunded 100%</li>
                            <li>For any reason you cannot turn up on race day, no refund of any form will be given.</li>
                            <li>If this registration is being made on behalf of a minor, I confirm that I am the parent / guardian of the child and that he/she has my permission to take part in the event. I further concur that all the above rules shall apply to him/her as if he were a major.</li>
                            <li>Participants must personally appear for collecting the running Bibs. Bibs can be distributed in-absentee with written consent of the registered runners.</li>
                            <li>Runners will not be allowed to stay on the course beyond the cut-off time considering the safety and health issues.</li>
                            <li>We request total co-operation from runners in this regards.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Terms;
