import logo from './logo.svg';
import './App.css';
import SectionOne from './Components/SectionOne';
import SectionTwo from './Components/SectionTwo';
import SectionThree from './Components/SectionThree';
import Sectionfourth from './Components/Sectionfourth';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import MarathonRegistrarion from './Pages/MarathonRegistrarion';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Terms from './Pages/Terms';
import Terms1 from './Pages/Terms1';
import Terms2 from './Pages/Terms2';
import Faq from './Pages/Faq';
import SubmitRun from './Pages/SubmitRun';
import Download from './Pages/Download';

function App() {
  return (
    <>
      <Router>

        <Routes>
          {/* <Route path="/" element={<Home/>}/> */}
          <Route path="/" element={<MarathonRegistrarion />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/terms1" element={<Terms1 />} />
          <Route path="/terms2" element={<Terms2 />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/submit-run" element={<SubmitRun/>} />
          <Route path="/download" element={<Download />} />
          <Route path="/download-certificate" element={<Download />} />
        </Routes>

      </Router>
    </>
  );
};

export default App;
