import React from 'react'
import SectionOne from '../Components/SectionOne';
import SectionTwo from '../Components/SectionTwo';
import SectionThree from '../Components/SectionThree';
import Sectionfourth from '../Components/Sectionfourth';
import Footer from '../Components/Footer';
import Home from './Home';


function MarathonRegistrarion() {
  return (
    <>
    <Home/>
    <SectionTwo/>
    <SectionThree/>
    <Sectionfourth/>
    <Footer/>
    </>
  )
}

export default MarathonRegistrarion