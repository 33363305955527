import React, { useState } from 'react'
import { Button, FormGroup, Input, Label } from 'reactstrap';
import * as htmlToImage from 'html-to-image';
import { usePDF } from 'react-to-pdf';

// const baseUrl = "http://localhost:5000";
// const baseUrl = "https://api.dotdx.in";
const baseUrl = "https://monkfish-app-zxijf.ondigitalocean.app";

const Download = () => {
    const [value, setValue] = useState({
        runveerId: '',
        phone: '',
    });
    const [value1, setValue1] = useState({
        name: '',
        run: ''
    });
    const [flag, setFlag] = useState(false);

    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setFlag(true);

        let resp = await fetch(`${baseUrl}/api/certificate/downloadCert`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ runveerID: value.runveerId, mobileNumber: value.phone })
        });
        let data = await resp.json();
        // console.log(data);

        if (data.statusCode === 200) {
            if (data.data) {
                setValue1({
                    name: data.data.name,
                    run: data.data.raceCategory.slice(0, -2)
                });

                setTimeout(() => {
                    var node = document.querySelector('.temp');
                    node.style.display = 'block';
                    htmlToImage.toPng(node)
                        .then(async function (dataUrl) {
                            document.querySelector('.cert-p1').innerText = data.data.name;
                            document.querySelector('.cert-p2').innerText = data.data.raceCategory.slice(0, -2);
                            var link = document.createElement('a');
                            link.download = 'certificate.png';
                            link.href = dataUrl;
                            link.click();
                            // toPDF();
                            node.style.display = 'none';
                        })
                        .catch(function (error) {
                            console.error('oops, something went wrong!', error);
                        });
                    setFlag(false);
                }, 2000);
            }

            setValue({
                runveerId: '',
                phone: '',
            });
        }
    };

    return (
        <>
            <div className="sub">
                <div className="sub1">
                    <div className="sub11">
                        <h2>Download Certificate </h2>
                        {flag && <p>Please wait ...</p>}
                    </div>
                    <div className="sub12">
                        <form onSubmit={handleSubmit}>
                            <Input name="runveerId" onChange={handleChange} value={value.runveerId} placeholder='Runveer ID' required />
                            <Input name="phone" onChange={handleChange} value={value.phone} placeholder='Registered Mobile Number' required />
                            <Button type='submit'>Submit</Button>
                        </form>
                    </div>
                </div>
            </div>

            <div className='cert-main temp' ref={targetRef}>
                <img src="/images/cert.png" alt="" />
                <p className="cert-p1">{value1.name}</p>
                <p className="cert-p2">{value1.run}</p>
            </div>
        </>
    );
};

export default Download;
