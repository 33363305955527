import React from 'react';

function ShowErrorMessage({ message }) {
  return (
    <div
      style={{
        width: '100%',
        marginTop: '15px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#EA5455',
      }}
    >
      <div>
        <p variant="body1" style={{ color: 'white' }}>
          {message}
        </p>
      </div>
    </div>
  );
}
export function ShowSuccess({ message }) {
  return (
    <div
      style={{
        width: '100%',
        marginTop: '15px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#28C76F',
      }}
    >
      <div>
        <p variant="body1" style={{ color: 'white' }}>
          {message}
        </p>
      </div>
    </div>
  );
}

export default ShowErrorMessage;
