import React from 'react'

const Faq = () => {
    return (
        <>
            <div className="faq">
                <div className="faq1">
                    <h1 className='text-center'>FAQ</h1>

                    <div className="faq11">
                        <p>
                            1. <b>Application To Use.</b> 	There are number of apps which we have listed for your use & most of them are free to download from Playstore. The participant  are at liberty to use any app amongst them. The apps allowed for the event are mentioned below.
                        </p>
                        <p>a. 	Strava, MapMyRun, Runkeeper, Google Fit .</p>
                        <p>b. 	Adidas Running, Nike Run Club, Pumatrac, Pacer .</p>
                        <p>c. 	Wearable/ Smartwatch- garmin, Apple, FitBit, Suunto, Samsung, Xiaomi .</p>
                        <p><b>Is It Just a running Event? </b>  NO, not at all. Following are the activities which you can perform</p>
                        <p>a.     Walk</p>
                        <p>b.     Jog</p>
                        <p>c.     Run</p>
                        <p>3. <b>If my GPS doesn’t work?</b></p>
                        <p>Don’t worry! If it doesn’t work. Sometimes when we try to find GPS it takes time & especially indoor it doesn’t work.  You should start your run when your GPS is in place and your activity is being logged. If the GPS doesn't work enroute for any reason, we trust you, just send your data. We will verify it with the App developers. But, ensure you do the run with functional app.</p>
                        <p>4. <b>Can I go and run outside the home?</b></p>
                        <p>Ofcourse, you should run outside the home and it is more preferable for tracking via GPS as well. Try avoiding running on treadmill as it is difficult for the GPS to log your record. The event is spread over a duration of 3 days for your ease to run as per your convenience and upload your data within 48 hrs of the event. But, please note that the run is to be completed in one go.</p>
                        <p>5. <b>How to Send / Upload data ?</b></p>
                        <p>a.     On event day, that is 27/28/29 October, morning, a portal will be open on the website for submitting your data. When you finish your activity, please click the link & attach/upload your data along with your booking id/ ticket id.</p>
                        <p>b.     If this bothers you- just email your data along with booking id/ticket id on our official email.</p>
                        <p><b>Email</b>: infrantrymarathon@gmail.com</p>
                        <p>c.     Be safe and hydrated.</p>
                        <p>d.     Temperature is soaring during day and chilly in night, so if you are running, walking or jogging wear a cap or cover your head.</p>
                        <p>e.     Consume water or other hydration fluid at regular intervals, know your ‘sweat Rate’ and consume fluid in proportion to it.</p>
                        <p>f.       If you don’t know Sweat rate it would be ideal to drink 200-250ml of fluids every 20 mins.</p>
                        <p>6. <b>This is a Virtual Event</b></p>
                        <p>a.     Participants need to understand this is not a physical event. Hence, we as organizers cannot provide safety aid or any kind of Emergency services like ambulance or doctor.</p>
                        <p>b.     Participants are requested to do responsible running. The organizers will not be responsible for any mishaps.</p>
                        <p>7. <b>It is essential to warm up before the activity. </b> Exercise at a slower pace in the first 5 minutes.</p>
                        <p>8. <b>Medical Checkup.</b></p>
                        <p>a.     Each participant is requested to check with a doctor to inspect his/her health for participating in the Run. This is not a race, so walk/Jog/Run at a very comfortable pace.</p>
                        <p>b.     Everyone is a winner. Don’t rush.</p>
                        <p>9. <b>Query and bulk registration: </b></p>
                        <p>For any query and Bulk registration contact : 7898893694</p>
                        <p>10. <b>Warning</b></p>
                        <p>a. 	Please seek medical assistance immediately if you experience chest discomfort, unusual shortness of breath, nausea or giddiness during activity.DO NOT PROCEED WITH THE ACTIVITY. Your health is of paramount importance.</p>
                        <p>b.    Do not experiment with anything new- be it your running pattern, goals, shoes, clothes, food or energy drinks on the day of the activity.</p>
                        <p>STAY SAFE- STAY POSITIVE-WALK-JOG-RUN-BE A PART OF INDIA’S BIGGEST VIRTUAL RUN- A RUN FOR OUR SOLDIERS</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq
